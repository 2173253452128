<template>
    <div class="edit-wrap edit_wrap_view">
        <div class="edit-hd">
            <div class="btn-wrap">
                <el-button @click="back">返回</el-button>
            </div>
            <h4 class="title">查看文章</h4>
        </div>
        <div class="edit-bd  article_edit_view">
            <el-form>
                <div class="edit-box">
                    <el-input
                        v-model="title"
                        readonly
                    ></el-input>
                    <span class="title_tip">{{fontCount}}/35字</span>
                    <quill-editor
                        v-model="content"
                        ref="myQuillEditor"
                        style="height: 500px;"
                        :options="editorOption"
                        readonly
                    ></quill-editor>
                </div>

                <el-form-item label="分类">
                    <el-input
                        type="text"
                        v-model="cate_id"
                        readonly
                    ></el-input>
                </el-form-item>

                <!-- <el-form-item label="标签">
                    <el-input
                        type="text"
                        style="width:600px;"
                        readonly
                    ></el-input>
                </el-form-item> -->

                <el-form-item label="缩略图">
                    <img
                        v-if="img"
                        :src="util.reImg(img)"
                        class="avatar"
                        style="width:146px;height:146px;border-radius:5px;"
                    />
                </el-form-item>

                <el-form-item label="上传附件">
                    <p class="tips">每个附件小于5M文件，格式为JPG,PNG,DOC,PDF,BMP或压缩包文件</p>
                    <!-- <p class="tips">最多可上传3个附件，每个小于5M文件，格式为JPG,PNG DOC PDF或BMP</p> -->
                    <ul class="upload-items">
                        <li
                            class="upload uploaded"
                            v-for="item in fileList"
                            :key="item.hash"
                        >
                            <span class="icon">
                                <img :src="$img.upload_icon2" />
                            </span>
                            <div class="upload-txt">
                                <h4
                                    class="name"
                                    v-text="item.name"
                                ></h4>
                                <p>
                                    <strong
                                        class="size"
                                        v-text="item.size"
                                    ></strong>
                                    <span class="date">{{item.date}}上传</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </el-form-item>

                <el-form-item label="下载需点券">
                    <el-input
                        v-model="download_point_count"
                        type="number"
                        readonly
                    ></el-input>&nbsp;&nbsp;点券
                </el-form-item>

                <!-- <el-form-item label="下载需费用">
                    <el-input
                        v-model="download_price"
                        type="number"
                        readonly
                    ></el-input>&nbsp;&nbsp;元
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>
<script>
import TopNav from "@/components/common/TopNav.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
    name: "views",
    components: {
        TopNav, // 头部吊顶
        BreadCrumb, // 面包屑
        GlobalFooter, // 公共底部
        quillEditor //编辑器
    },
    data() {
        return {
            editorOption: {},
            radio: 1,
            fontCount: 0,
            title: "",
            content: "",
            cate_id: "",
            tag: "",
            img: "",
            download_point_count: 0,
            download_price: 0,
            fileList: []
        };
    },
    methods: {
        //返回文章列表
        back() {
            this.$router.push("/user/article/1");
        },
        //监听标题文字长度
        titleInput(text) {
            this.fontCount = text.length;
        },
        //获取文章内容
        getDetail() {
            let _this = this;
            this.post(
                "/article/article/get_article_info",
                { id: _this.$route.params.id },
                function (res) {
                    if (res.code == 200) {
                        _this.title = res.data.title;
                        _this.id = _this.$route.params.id;
                        _this.content = res.data.content;
                        _this.cate_id = res.data.cate_id.value;
                        _this.tag = res.data.tag ? res.data.tag : "";
                        _this.img = res.data.img;
                        _this.fileList = res.data.annex;
                        _this.download_point_count =
                            res.data.download_point_count;
                        _this.download_price = res.data.download_price;
                        if (
                            _this.download_price > 0 ||
                            _this.download_point_count > 0
                        ) {
                            _this.radio = 2;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        }
    },
    created() {
        this.getDetail();
    }
};
</script>
<style lang="less">
@import "../../../styles/article.less";
.el-select {
    width: 240px;
}
.edit-box {
    position: relative;
}
.edit-box .title_tip {
    font-size: 16px;
    position: absolute;
    right: 30px;
    top: 40px;
    color: rgb(209, 206, 206);
}
.upload-items .upload.uploaded .upload-txt {
    width: 74%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.edit-bd .el-form-item .el-form-item__content {
    text-align: left;
}
.edit-bd .el-form-item .el-form-item__content .tips {
    cursor: pointer;
}
.edit-bd .el-form-item .el-form-item__label {
    width: 120px;
}
.edit-bd .el-form-item .el-form-item__content {
    margin: 0;
    float: left;
}
.edit-bd .el-form-item .el-form-item__content .el-input {
    width: 180px;
}
.edit-wrap .edit-hd {
    padding: 0;
}
.edit-wrap .edit-hd .title {
    padding-left: 0;
}
.edit_wrap_view {
    padding: 10px;
    .edit-hd {
        background: #fff;
        padding: 20px 20px 0 20px !important;
        height: 60px !important;
        .title {
            padding-left: 0px !important;
        }
    }
}
.article_edit_view {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    .el-form-item__label {
        width: 130px !important;
        float: left;
    }
    .el-form-item__content {
        width: 700px;
        margin: 0 !important;
        float: left;
    }
}
</style>


